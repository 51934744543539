import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import SocialProfile from '../../components/social-profile/social-profile';
import {
  IoLogoFacebook
} from 'react-icons/io';
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from './style';

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/TinyTogether/',
    tooltip: 'Facebook',
  },
  // {
  //   icon: <IoLogoInstagram />,
  //   url: 'https://www.instagram.com/TinyTogether/',
  //   tooltip: 'Instagram',
  // },
  // {
  //   icon: <IoLogoTwitter />,
  //   url: 'https://twitter.com/TinyTogether',
  //   tooltip: 'Twitter',
  // },
  // {
  //   icon: <IoLogoLinkedin />,
  //   url: 'https://www.linkedin.com/company/redqinc/',
  //   tooltip: 'Linked In',
  // },
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  const getMonths = () => {
    const start = new Date(2000, 6, 7);
    const end = new Date();
    const months = ((end.getFullYear() - start.getFullYear()) * 12) + (end.getMonth() - start.getMonth());
    return `${months}`;
  }
  
  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>About Us</h2>
        <p>
          Need to add info about Tiny Together here..
        </p>
      </AboutPageTitle>

      <AboutImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
      </AboutImage>

      <AboutDetails>
        <h2>What is there to say?</h2>
        <p>
          We've been together since '95, have a little girl (of {getMonths()} months), 
          and were both in the US Marines.  We both work in the technology field.  She 
          is a network engineer and he is a software developer.
        </p>
        <p>
          Let's see... what else?
        </p>

        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
